<template>
    <div class="comparisons-area ptb-70 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Compáranos en el mercado</h2>
                <div class="bar"></div>
                <p>Creamos experiencia diferente a las que ofrece el mercado, tipo de cambio justo, seguridad, atención rápida y personalizada, y sobre todo muy fácil de usar gracias a nuestra plataforma de atención por whatsapp.</p>
            </div>

            <div class="comparisons-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Cualidades</th>
                            <th scope="col">Calle</th>
                            <th scope="col">Bancos</th>
                            <th scope="col">Casa Digitales</th>
                            <th scope="col">Cambios JP</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr>
                            <td>Protección en todo momento</td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                        </tr>
                        <tr>
                            <td>Transferencias rápidas</td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                        </tr>
                        <tr>
                            <td>Mejor tipo de cambio</td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                        </tr>
                        <tr>
                            <td>Fácil uso</td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-cancel"></i></td>
                            <td><i class="flaticon-check-mark"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Comparisons'
    }
</script>