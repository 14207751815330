<template>
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"></div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Business Currency Transfers</h3>
                        <p>Donec sollicitudin molestie malesuada. proin eget tortor risus. nulla rutrum congue quis lorem ut libero malesuada feugiat donec rutrum congue leo eget malesuada.</p>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                        <ul class="list">
                            <li> 
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Receive Foreign Payments
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Budget Effectively
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Manage Currency Risk
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Highly Competitive Exchange Rates
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <router-link to="/sign-up" class="btn btn-primary">Register Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusinessCurrency'
}
</script>