<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>POLÍTICA DE PRIVACIDAD DE DATOS</h2>
                <div class="bar"></div>
            </div>

            <div class="privacy-policy-content">
                <p>El presente documento contiene la Política de Privacidad de Datos de <b>MC INVERSIONES JAEN S.A.C.</b> (en adelante <b>Cambios JP</b>), las mismas que se dan en armonía de lo dispuesto por la Ley de Protección de Datos Personales (Ley N° 29733), su reglamento (D.S. N° 003-2013-JUS) y demás normas complementarias y/o conexas vigentes.</p>

                <p>Le solicitamos que por favor lea detalladamente esta política para conocer los tipos de información que recolectamos de usted, cómo usamos esa información. Al visitar nuestra plataforma web (<a href="www.cambiosjp.com">www.cambiosjp.com</a>) usted acepta conscientemente las prácticas descritas en esta política.</p>
            
                <p>En caso de no estar de acuerdo con las Políticas de Privacidad de Datos, no podría hacer uso de la plataforma, ni de los servicios o productos que brindamos. Es por ello que el cliente declara haber leído y aceptado de manera previa y expresa las Políticas de Privacidad de Datos, sujetándose a sus disposiciones.</p>            
            
                <p>Para propósitos de las leyes asociadas a la protección de datos, el controlador de la información es <b>Cambios JP</b>, con <b>RUC N° 20605694480</b>, con domicilio fiscal en c. Las Begonias Nº 111 Urb. Las Flores, Jaen, Cajamarca, Perú.</p>         
            </div>

            <div class="privacy-policy-content">
                <h3>TRATAMIENTO DE DATOS PERSONALES</h3>
                <p>Cuando el usuario acepta los términos y condiciones por el uso de los servicios ofrecidos por nuestra plataforma web (<a href="www.cambiosjp.com">www.cambiosjp.com</a>), nos brinda su consentimiento para recopilar datos personales adicionales. <b>Cambios JP</b> se preocupa por la seguridad, privacidad y confidencialidad de sus datos personales, los mismos que no serán 
                divulgados a terceros, salvo que sean requeridos por mandato judicial o a solicitud de la Unidad de Inteligencia Financiera (UIF) en vista que siendo una sociedad supervisada por la SBS se tiene la obligación de reportar actividades y/u operaciones relacionadas al lavado de activos y financiamiento del terrorismo.</p>

                <p>Asimismo, el usuario consiente que <b>Cambios JP</b> puede recopilar, almacenar y realizar el tratamiento de Datos Personales para fines distintos a la relación contractual, tales como: realizar publicidad, ofertas, promociones, suscripciones y servicios ofrecidos, los mismos que serán enviados o comunicados a través de correo electrónico,
                vía telefónica o de otra forma que el cliente tenga registrado con <b>Cambios JP</b>, las mismas que podrán ser canceladas en cualquier momento.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>USO DE LA INFORMACIÓN</h3>
                <p>Nuestro propósito es brindarle el mejor servicio posible y mantenerlo informado de nuestros productos y servicios.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>COOKIES</h3>
                <p>Nuestra plataforma emplea cookies para distinguirlo de cualquier otro usuario, los mismos que facilitan el uso y la navegación en nuestra página web (<a href="www.cambiosjp.com">www.cambiosjp.com</a>), lo que nos permite mejorar la calidad y experiencia hacia usted de nuestro servicio. Los cookies pueden borrarse del ordenador si el cliente lo desea 
                en cualquier momento. El cliente puede aceptar o negar el uso de cookies. La utilización de nuestra plataforma implica su aceptación del uso de cookies por nuestra parte.</p>
            
                <p>Recuerda que es posible desactivar las cookies almacenadas en su computadora cambiando la configuración de su navegador. Sin embargo, esto podría afectar el correcto funcionamiento de nuestra plataforma.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>SEGURIDAD</h3>
                <p>Toda la información que nos envíe se almacena en nuestros servidores seguros. Cualquier transacción de pago se encriptará usando SSL y/o alguna otra tecnología de seguridad cibernética. Usted es responsable de mantener la confidencialidad de la contraseña seleccionada para acceder a ciertas partes de la plataforma web. Le pedimos no compartir su contraseña con nadie, ya que <b>Cambios JP</b> no se hará responsable ante la pérdida o robo de su contraseña.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Cambios a la política de privacidad</h3>
                <p><b>Cambios JP</b> se reserva el derecho de cambiar, modificar y actualizar las condiciones de las políticas de privacidad en cualquier momento., las mismas que serán publicadas en esta plataforma y notificada a usted al correo electrónico que nos ha proporcionado. En todo caso, se recomienda revisar frecuentemente la plataforma para ver cualquier actualización o cambios a nuestra política de privacidad.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Resolución de conflictos</h3>
                <p>Las partes se someten a la competencia de los jueces y tribunales de la ciudad de Lima, en caso se requiera resolver cualquier duda, diferencia o controversia que pudiera surgir entre las partes respecto a los alcances de las Políticas de Privacidad de Datos aquí señaladas, renunciando al fuero que pudiera corresponderles en razón a su domicilio.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Marco Normativo</h3>
                <p>Ley N° 29733 - Ley de Protección de Datos Personales.<br>
                Superintendencia de Banca, Seguros y AFP.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Contáctanos</h3>
                <p>Para cualquier pregunta, solicitud y comentarios sobre nuestras Políticas de Privacidad de Datos, solicitamos escribirnos al correo <b>contacto@cambiosjp.com</b>.</p>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicyArea'
    }
</script>