<template>
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>How We Can Help</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Incoming payments</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Outgoing payments</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Risk management solutions</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Online sellers</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Protecting'
}
</script>