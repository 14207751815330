<template>
    <div class="ctp-world-area pb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-world-content">
                        <h3>Receive money from all over the world</h3>
                        
                        <div class="world-inner-card">
                            <h4>Get paid like a local</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere sit amet aliquam cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                        <div class="world-inner-card">
                            <h4>Convert your money in seconds</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices sit amet aliquam posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-world-image"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReceiveMoney'
}
</script>