<template>
    <div class="ctp-services-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Personal Currency Transfers Services</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/mobile-payment.svg" alt="image">
                            </div>
                            Regular Payments
                        </h3>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar. quisque velit nisi, pretium ut lacinia in, elementum id enim. proin eget tortor risus. proin eget tortor risus.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/warning.svg" alt="image">
                            </div>
                            Rate Alerts
                        </h3>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar. quisque velit nisi, pretium ut lacinia in, elementum id enim. proin eget tortor risus. proin eget tortor risus.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/fluctuation.svg" alt="image">
                            </div>
                            Market Analysis
                        </h3>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar. quisque velit nisi, pretium ut lacinia in, elementum id enim. proin eget tortor risus. proin eget tortor risus.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/contract.svg" alt="image">
                            </div>
                            Spot Contracts
                        </h3>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar. quisque velit nisi, pretium ut lacinia in, elementum id enim. proin eget tortor risus. proin eget tortor risus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>