<template>
    <div class="ctp-key-features-area ptb-100">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Money Transfer Company Key Features</h2>
            </div>

            <div class="ctp-key-features-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="security-tab" data-bs-toggle="tab" href="#security" role="tab" aria-controls="security">Security</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="cost-tab" data-bs-toggle="tab" href="#cost" role="tab" aria-controls="cost">Cost</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="speed-tab" data-bs-toggle="tab" href="#speed" role="tab" aria-controls="speed">Speed</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="accessibility-tab" data-bs-toggle="tab" href="#accessibility" role="tab" aria-controls="accessibility">Accessibility</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="security" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="flaticon-check-mark"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="flaticon-check-mark"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="flaticon-check-mark"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="flaticon-check-mark"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="cost" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="flaticon-check-mark"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="flaticon-check-mark"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="flaticon-check-mark"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="flaticon-check-mark"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="speed" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="flaticon-check-mark"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="flaticon-check-mark"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="flaticon-check-mark"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="flaticon-check-mark"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="accessibility" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="flaticon-check-mark"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="flaticon-check-mark"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="flaticon-check-mark"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="flaticon-check-mark"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
export default {
    name: 'KeyFeatures'
}
</script>