<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Términos y Condiciones</h2>
                <div class="bar"></div>
                <p></p>
            </div>
    
            <div class="privacy-policy-content">
                <p>Estos términos y condiciones (contrato) informan bajo qué reglas se accede a los Servicios ofrecidos por <b>Cambios JP</b> y otra información importante.</p>

                <p>Por favor, lee cuidadosamente este contrato y asegúrate de que lo entiendes antes de usar nuestros servicios.</p>

                <p>El presente acuerdo es celebrado entre usted (en adelante: <b>el usuario</b>) y MC INVERSIONES JAEN S.A.C. <b>(Cambios JP)</b>, el cual detalla los servicios que presta y las condiciones que debe aceptar para acceder a los mismos.</p>

                <p>Para usar nuestros servicios debes aceptar este contrato y nuestra <b>Política de Privacidad de Datos</b> de manera electrónica. En el caso de que no te encuentres de acuerdo con el mismo, deberás abstenerte de utilizar la plataforma y los servicios. Al registrarte, declaras expresamente haber leído, comprendido y aceptado íntegramente estos documentos. No obstante, con el uso de nuestra plataforma y/o nuestros servicios, confirmas que aceptas este contrato.</p>

                <p>Mediante la utilización de la plataforma usted toma conocimiento que éstos serán los términos y condiciones que se encuentren vigentes en ese momento, y que presta conformidad en forma irrevocable a los mismos, quedando sujeto a estos para cualquier operación que realice posteriormente dentro de nuestra plataforma.</p>
            
                <p>El uso de nuestros servicios implica la plena y total aceptación de los términos y condiciones aquí señalados y acepta eximir de responsabilidad a <b>Cambios JP</b> por la omisión o falta de lectura total o parcial del contenido del presente acuerdo.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Cambios JP</h3>
                
                <p><b>Cambios JP</b> es una empresa de tecnología financiera que se rige bajo las leyes del Perú, constituida como <b>MC INVERSIONES JAEN S.A.C.</b>, identificada con <b>RUC N° 20605694480</b>, y con domicilio fiscal en c. Las Begonias Nº 111 Urb. Las Flores, Jaen, Cajamarca, Perú.</p>
            
                <p><b>Cambios JP</b> ofrece sus servicios a través de su portal web: <a href="www.cambiosjp.com"> www.cambiosjp.com </a>. Asimismo, por resolución <b>Nº Reg SBS: 00555-2020</b>, <b>Cambios JP</b> se encuentra registrada como casa de cambio digital ante la SBS, encontrándose supervisada por la Unidad de Inteligencia Financiera del Perú (UIF).</p>
           
                <p><b>Cambios JP</b> publica información orientativa en torno a sus servicios pero no brinda asesoría ni recomendaciones financieras, por lo que ninguna comunicación debe ser interpretada como tal.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>Terminología General</h3>
                <ul>
                    <li><b>LA PLATAFORMA O WEB:</b> Es el sitio web <a href="www.cambiosjp.com"> www.cambiosjp.com </a> a través del cual <b>Cambios JP</b> provee sus servicios de cambio de divisas en el Perú.</li>
                    
                    <li><b>EL USUARIO:</b> Cualquier persona natural o jurídica, con plena capacidad para contratar, que utilice los servicios brindados por la plataforma de <b>Cambios JP</b>.<br>
                        Igualmente se entiende por aquella persona natural o jurídica que realiza una operación de compra/venta de soles/dólares utilizando la plataforma de <b>Cambios JP</b>.<br>
                        <b>Cambios JP</b> sólo ofrece el servicio de compra o venta de dólares americanos a EL USUARIO que cuente con una cuenta bancaria en una  entidad financiera supervisada por la SBS, en las que pueda transferir y recibir el dinero por el servicio de cambio de divisas.
                        EL USUARIO, al hacer uso de los servicios brindados por <b>Cambios JP</b> se compromete a mantener actualizados los datos brindados, o actualizarlos cuando ello se requiera. Asimismo, cada cuenta registrada en Cambios JP es de uso personal e intransferible.</li>
                    
                    <li><b>TIPO DE CAMBIO:</b> Es aquella cotización de compra y de venta de dólares americanos consignada en nuestra plataforma. El tipo de cambio fijado en la operación tendrá una vigencia máxima de quince (15) minutos desde el momento del registro de la operación, tiempo en el cual EL USUARIO deberá realizar la
                         transferencia del monto acordado a la cuenta bancaria indicada de <b>Cambios JP</b>, seguidamente deberá adjuntar o enviar el voucher o constancia de la transferencia al número de whatsapp indicado y/o consignar el número de su transacción bancaria. En caso EL USUARIO realice la transferencia pasado el tiempo 
                         de vigencia máxima, se propondrá un nuevo tipo de cambio en base a la fecha y hora de la recepción del monto que figura en los movimientos de la cuenta bancaria de <b>Cambios JP</b> válida para todas las operaciones de compra y/o venta de dólares realizadas entre sus miembros.</li>
                    
                    <li><b>COMPRA:</b> Operación donde EL USUARIO transfiere dólares americanos a la cuenta de <b>Cambios JP</b> y una vez verificada la disponibilidad de fondos de la transferencia, <b>Cambios JP</b> procederá a transferir soles en la cuenta de EL USUARIO, para lo cual aplicará el tipo de cambio publicado en la web.</li>
                    
                    <li><b>VENTA:</b> Operación donde EL USUARIO transfiere soles a la cuenta de Cambios JP y una vez verificada la disponibilidad de fondos de la transferencia, <b>Cambios JP</b> procederá a transferir dólares americanos en la cuenta de EL USUARIO, para lo cual aplicará el tipo de  cambio publicado en la web.</li>
                    
                    <li><b>CUENTAS Cambios JP:</b> Son las cuentas bancarias en soles y  dólares americanos cuyo titular es Cambios JP, las mismas que son publicadas en nuestra web <a href="www.cambiosjp.com"> www.cambiosjp.com</a>.</li>
                    
                    <li><b>TRANSFERENCIA BANCARIA:</b> Cuando EL USUARIO realiza las transferencias de su banco a las cuentas de <b>Cambios JP</b> del mismo banco.</li>
                    
                    <li><b>TRANSFERENCIA INTERBANCARIA:</b> Cuando las transferencias son entre cuentas de bancos locales distintas a las que tiene <b>Cambios JP.</b></li>
                    
                    <li><b>TRANSFERENCIA INTERPLAZA:</b> Es el caso cuando EL USUARIO tiene cuentas en otra plaza que no es la ciudad de Lima, en este  caso, <b>Cambios JP</b> deducirá del importe a transferir como consecuencia de la  operación los costos bancarios asociados al envío de dinero a otra plaza.</li>
                    
                    <li><b>TRANSFERENCIAS INMEDIATAS:</b> Es aquella transferencia interbancaria que se pueden ejecutar en un tiempo aproximado de quince (15) minutos, donde la entidad financiera establecerá una comisión por dicho servicio que EL USUARIO tendrá que asumir.</li>
                    
                    <li><b>IMPUESTO A LAS TRANSACCIONES FINANCIERAS (ITF):</b> Es un Impuesto creado en el año 2003, que grava determinadas transacciones financieras sobre el monto de la operación. La tasa vigente es de 0.005%, la misma que podría ser modificada de acuerdo a lo regulado por el Estado.</li>
                    
                    <li><b>CÓDIGO DE CUENTA INTERBANCARIO (CCI):</b> Es el código que permite identificar una cuenta en el sistema financiero bancario, el cual está compuesto por 20 dígitos que permite realizar transferencias entre los diferentes bancos. El CCI es proporcionado por la entidad  financiera donde EL USUARIO tiene sus fondos.</li>
                    
                    <li><b>PERSONA NATURAL:</b> Es aquella persona residente o no residente en el Perú que haya cumplido la mayoría de edad y que esté debidamente identificado con documento de identidad emitido por su país de origen o por el Estado Peruano.</li>
                    
                    <li><b>PERSONA JURÍDICA:</b> Es aquella entidad o sociedad inscrita en los Registros Públicos del Perú que tiene personería jurídica capaz de suscribir contratos (acuerdos vinculantes) con terceros.</li>
                    
                    <li><b>SUPERINTENDENCIA DE BANCA, SEGUROS Y AFP (SBS):</b> Organismo encargado de la regulación y supervisión del sistema financiero, de seguros y del sistema privado de pensiones en el Perú, así como de prevenir y detectar el lavado de activos y financiamiento del terrorismo.</li>
                    
                    <li><b>PLAZO DE RECEPCIÓN DE FONDOS:</b> Tiempo que transcurre desde que EL USUARIO realiza su orden de compra en la plataforma de <b>Cambios JP</b>, hasta que transfiere el importe materia de compra o venta a la cuenta de <b>Cambios JP</b> para ser procesada. Este lapso de tiempo no podrá ser mayor de quince (15) minutos. Estas operaciones deberán realizarse preferentemente durante el horario de atención de la web.</li>
                </ul>    
            </div>

            <div class="privacy-policy-content">
                <h3>OBJETO DEL PRESENTE CONVENIOS</h3>
                <p>Los términos y condiciones aquí señalados regulan el acceso y utilización de la plataforma por parte de EL USUARIO. El simple uso y acceso a las aplicaciones o uso de sus servicios digitales que <b>Cambios JP</b> pone a su disposición, se entenderá como la aceptación de los términos y condiciones señalados en el presente documento, lo que conlleva el compromiso de respetar las condiciones de su uso.</p>

                <p>Los servicios ofrecidos en la Plataforma se encuentran dirigidos a personas naturales mayores de 18 años de edad cumplidos, que cuenten con un documento de identidad válido y vigente otorgado por autoridad peruana competente (DNI, Carné de Extranjería o pasaporte) y que tengan capacidad legal para contratar. Asimismo, la Plataforma brindará servicios a personas jurídicas que se encuentren registradas ante la SUNAT y que cuenten con un RUC que tenga la condición de activo.</p>
            
                <p><b>Cambios JP</b> podrá realizar las acciones que considere necesarias para verificar la autenticidad de la identidad del usuario, pudiendo también solicitar información adicional necesaria para este fin. Asimismo, <b>Cambios JP</b> se reserva el derecho de ejercer las acciones que estime pertinentes para solicitar información adicional a las entidades del estado y/o privadas con el objetivo de validar si la información registrada por EL USUARIO al momento de aperturar su cuenta en la plataforma es real y de corroborar la autenticidad o veracidad de la información consignada en ella.</p>
            
                <p>El acceso y utilización de la plataforma implica la aceptación sin reservas de todas las disposiciones incluidas en el presente convenio, siendo obligación de EL USUARIO leer atentamente los mismos antes de hacer uso de la plataforma y los servicios ofrecidos por <b>Cambio JP</b> a través de la plataforma.</p>
             
                <p>El acceso y utilización de la plataforma por parte de EL USUARIO es libre y gratuito; no obstante ello, <b>Cambios JP</b> se reserva el derecho de negar o restringir en cualquier momento a EL USUARIO el acceso total o parcial a la plataforma cuando exista una causa relacionada a la seguridad, operaciones fraudulentas y/o delictivas, privacidad, libertad de contratar, identificaciones falsas, o cualquier otra que resulte razonable para ello. En razón a lo señalado, <b>Cambios JP</b> se reserva el derecho de rechazar la apertura de cuentas respecto a personas cuya cuenta haya sido dada de baja o suspendida.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>COMUNICACIONES</h3>
                <p>Todas tus declaraciones y actos realizados vía electrónica tienen el carácter de una manifestación válida de tu voluntad, la cual podrá realizarse a través de comunicaciones y/o clics en dispositivos a través de la plataforma, luego de acceder a tu cuenta como usuario, identificándose con la cuenta de Whatsapp vinculada al número de teléfono móvil registrado en la base de datos de usuarios.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>REGISTRO DEL USUARIO O EL CLIENTE</h3>
                <p>Para hacer uso de la plataforma y los servicios de <b>Cambios JP</b>, EL USUARIO deberá registrarse como persona natural (PN) o persona jurídica (PJ).</p>
            
                <p>El usuario generado es de uso personal y no está permitido que actúe a nombre de una tercera persona. EL USUARIO registra sus datos personales y adjunta una imagen de su documento de identidad que da certeza de la información de los datos, registra los datos de las cuentas bancarias,
                   crea su usuario y clave la cual es personal e intransferible, siendo responsabilidad de EL USUARIO no compartirla y de asumir la responsabilidad del uso indebido de la misma. <b>Cambios JP</b> podrá solicitar información adicional a EL USUARIO, en caso considere, a su sólo criterio, 
                   que la información proporcionada no sea legible o que no sea suficiente para probar que actúa en nombre de la persona natural o jurídica que se indica.</p>
           
                <p>En caso que <b>Cambios JP</b> detecte que un mismo usuario posee dos o más cuentas de registro en la plataforma, esta se reserva el derecho de cerrar o fusionar las mismas, sin mediar aviso previo al USUARIO de dichas cuentas.</p>
           
                <p>Asimismo, <b>Cambios JP</b> se reserva el derecho de rechazar cualquier nuevo usuario o transferencia a discreción y sin expresión de causa ante la sola sospecha que se estén usando la plataforma para realizar transacciones u operaciones fraudulentas y/o delictivas.</p>
           
                <p>Igualmente, <b>Cambios JP</b> se encuentra facultado para dar de baja a cuentas, ante la sospecha que están siendo utilizadas para la ejecución de operaciones fraudulentas y/o delictivas.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>REGISTRO DE CUENTAS BANCARIAS</h3>
                <p>EL USUARIO deberá registrar en su perfil dentro de la plataforma web de <b>Cambios JP</b>, los datos de sus cuentas bancarias tanto en soles como en dólares americanos, la cuales deben ser de entidades financieras reguladas por la SBS. EL USUARIO es responsable del correcto registro de sus cuentas bancarias.</p>
            
                <p><b>Cambios JP</b> se reserva el derecho a rechazar una operación de compra o venta de divisas en caso detecte que el titular de la cuenta es distinto del usuario del perfil registrado para la operación, en vista que <b>Cambios JP</b> solo transfiere a cuentas cuyo titular o propietario es EL USUARIO y no a cuentas de terceros.</p>           
            </div>

            <div class="privacy-policy-content">
                <h3>OPERACIÓN PARA SERVICIO DE CAMBIO DE DIVISAS</h3>
                <p>Para realizar una operación de compra o venta de dólares, EL USUARIO debe estar registrado en la Plataforma web de <b>Cambios JP</b> y deberá acceder a su cuenta de usuario, luego de lo cual elegirá el tipo de operación que quiere realizar.</p>
           
                <p><b>Cambios JP</b> no cobra ningún tipo de comisión por los servicios que brinda en su plataforma.<br>
                Si EL USUARIO eligió la opción <b>compra</b>, se activará en la ventana del cotizador la opción envío, seguido del símbolo $ (USD) y EL USUARIO colocará el importe en dólares que desea vender a <b>Cambios JP</b>, por el cual se le transferirá soles a su cuenta bancaria., Si EL USUARIO eligió la opción <b>venta</b>, se activará en la ventana del cotizador 
                la opción envío, seguido del símbolo S/. (soles) y EL USUARIO colocará el importe en soles por la cantidad de dólares que desea comprar a <b>Cambios JP</b>, por el cual se le transferirá dólares a su cuenta bancaria.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>DE LA TRANSFERENCIA</h3>
                <p>Para ejecutar la orden de compra o venta de dólares americanos de EL USUARIO, esta deberá realizarla mediante la transferencia bancaria o interbancaria vía online y desde una cuenta personal o de la persona jurídica que representa a la cuenta bancaria a nombre de <b>Cambios JP</b>. EL USUARIO deberá elegir transferir los fondos a sus cuentas registradas.</p>
           
                <p>Para todos los casos, <b>Cambios JP</b> trasladará a EL USUARIO las comisiones, cargos, portes o cualquier otro concepto que la entidad bancaria involucrada cargue por la operación, cuando EL USUARIO:
                  <ul type="a">
                    <li>Use cuentas bancarias de plazas distintas a Lima y <b>Cambios JP</b> incurra en comisiones interplaza.</li>
                    <li>Realice un depósito en efectivo o en cheque, lo cual no está permitido por <b>Cambios JP.</b> En este caso la operación quedará anulada y se procederá a la devolución de los fondos depositados por EL USUARIO, previo descuento de los gastos generados por dicha transferencia, tales como comisiones, impuesto como el ITF y otros inherentes a ello.</li>
                    <li>Realice una transferencia en la agencia de su entidad bancaria y esta se encuentre en una plaza distinta a Lima. Realice operaciones de transferencia bancarias desde provincias distintas al BCP.</li>
                  </ul>
                </p>
                
                <p>Cuando EL USUARIO haya procedido conforme a lo señalado en el punto b. del párrafo anterior, en este caso, <b>Cambios JP</b> podrá, a su elección, retornar los fondos vía transferencia a cualquiera de las cuentas registradas por EL USUARIO o solicitar a EL USUARIO que brinde una cuenta bancaria en caso de no tenerla registrada o solicitará la emisión de un cheque a nombre de EL USUARIO previo descuento de los gastos incurridos.</p>
                
                <p>La transferencia de fondos deberá ser realizada dentro del plazo máximo de recepción de fondos (15 minutos) contados a partir del registro de su operación en la plataforma de <b>Cambios JP</b>, EL USUARIO asume su responsabilidad por las consecuencias de su demora. <b>Cambios JP</b> no tiene responsabilidad alguna por los tiempos de liquidación de las operaciones en que incurran las entidades bancarias.</p>
                 
                <p>Para estos efectos Cambios JP, no será responsable de:
                  <ul>
                    <li>Bloqueo de cuentas bancarias registradas por EL USUARIO.</li>
                    <li>De las comisiones o importes que cobren las entidades bancarias por concepto de transferencias las cuales serán asumidas por EL USUARIO.</li>
                    <li>De los errores, gastos, comisiones o demora en el tiempo incurridos debido a errores de EL USUARIO donde éste transfiere de una cuenta de una moneda a otra cuenta en moneda diferente.</li>
                  </ul>
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>ENVÍO DE LA CONSTANCIA</h3>
                <p>EL USUARIO deberá adjuntar el voucher o la constancia bancaria de su transferencia a <b>Cambios JP</b> o en su defecto consignar y enviar el número de su transacción u operación bancaria en la plataforma web y/o cuenta de whatsapp de <b>Cambios JP.</b></p>
            </div>

            <div class="privacy-policy-content">
                <h3>COMPROBANTE DE LA OPERACIÓN</h3>
                <p>Luego de culminada la operación <b>Cambios JP</b> enviará al correo registrado por EL USUARIO, el comprobante de la operación realizada.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>OPERACIONES INTERBANCARIAS</h3>
                <p><b>Cambios JP</b> tiene cuenta bancaria en dólares y soles en el Banco de Crédito del Perú (BCP) y Banco Continental (BBVA), por lo que las transferencias que se realicen a otros bancos serán consideradas transferencias interbancarias.</p>
            
                <p>EL USUARIO declara mediante la utilización de los servicios de <b>Cambios JP</b> que entiende y acepta que la disponibilidad del dinero objeto de transferencias interbancarias se encuentra sujeta a horarios y puede demorar hasta 48 horas o dos
                   (2) días útiles. El tiempo de la transferencia dependerá del banco emisor y de la Cámara de Compensación Electrónica (CCE). En ese sentido, el usuario asume la responsabilidad en caso de demora cuando la operación no sea realizada de lunes 
                   a viernes dentro del horario de atención, puesto que las operaciones sólo se procesan los días útiles.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>ANULACIÓN DE TRANSACCIONES</h3>
                <p>Si EL USUARIO se demora en realizar la transferencia y/o ésta exceda el plazo de recepción de fondos, <b>Cambios JP</b> no podrá mantener el tipo de cambio y la operación podrá quedar ANULADA. Se entiende por operación ANULADA, cuando esta última y el tipo de cambio no tiene vigencia y por tanto <b>Cambios JP</b> no asume el compromiso de mantener el tipo de cambio acordado por más de 15 minutos dentro de su horario de atención.</p>
            
                <p>En el supuesto de que EL USUARIO, pasado el plazo de recepción de fondos, haya realizado la transferencia del monto acordado en el registro de la operación o el monto acordado haya ingresado a la cuenta bancaria de <b>Cambios JP</b>, EL USUARIO podrá:
                  <ul>
                    <li>Aceptar el nuevo tipo de cambio en base a la fecha y hora en que fue recibido el fondo por parte de <b>Cambios JP</b>. La fecha y hora mencionada será la que indique la banca en línea de la entidad financiera que recibe los fondos.</li>
                    <li>Solicitar la devolución del monto, para lo cual <b>Cambios JP</b> procederá a hacer la devolución del monto en la cuenta registrada previamente; de modo que EL USUARIO acepta asumir los costos (comisiones, impuestos y otros) en que incurra <b>Cambios JP</b> para tales efectos.</li>
                </ul>
                </p>

                <p>Sin perjuicio de lo anteriormente mencionado, en caso <b>Cambios JP</b> detecte alguna anomalía en el tipo de cambio o no pueda procesar la operación, por razones ajenas a la responsabilidad de EL USUARIO, tendrá la posibilidad de anular la operación de cambio de moneda procediendo con la devolución inmediata del monto abonado por EL USUARIO.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>LÍMITES DE OPERACIONES E IMPORTE MÍNIMO</h3>
                <p>No hay límite máximo para el monto de una operación, sin embargo, <b>Cambios JP</b> se reserva el derecho de establecer límites de importe durante el día en curso. Para ello se le indicará a EL USUARIO al momento de realizar la operación en la plataforma o cuenta de whatsapp.</p>
            
                <p>La cantidad mínima para operaciones de cambio de divisas a través de cuentas bancarias es de cincuenta (50) dólares americanos o su equivalente en soles.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>TARIFAS Y COMISIONES</h3>
                <p><b>Cambios JP</b> no realiza ningún cobro de comisión por el uso de sus servicios en su plataforma.</p>

                <p>Sin embargo, EL USUARIO debe considerar que se podrían aplicar comisiones dependiendo del tipo de servicio y método del pago seleccionado. Además, EL USUARIO debe considerar que existen cobros de comisiones o el llamado Impuesto a las Transacciones Financieras (ITF) efectuados por las entidades financieras. Adicionalmente, en caso que EL USUARIO use cuentas bancarias de plazas distintas a la ciudad de Lima, <b>Cambios JP</b> trasladará las comisiones cargadas por las entidades financieras a EL USUARIO.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>MEDIDAS DE SEGURIDAD</h3>
                <p><b>Cambios JP</b> cuenta con una <b>Política de Privacidad de Datos</b> que deberás leer y aceptar junto con este contrato. Esta política es aplicada durante todo el proceso y el mantenimiento de la información del usuario de la plataforma. <b>Cambios JP</b> no revela los datos de su cuenta, direcciones postales, correo electrónico, operaciones, datos personales a terceros; excepto previa autorización del usuario o ante un mandato emitido por una autoridad competente.</p>
            
                <p>Todas las actividades de registro del usuario, datos personales, cuentas, operaciones, entrega de constancia de transferencia, validación de recepción de fondos en las cuentas de <b>Cambios JP</b>, entre otros, están sujetos a todos los controles de seguridad y de identificación del usuario que se realizan de acuerdo con nuestras <b>Políticas de Privacidad de Datos</b> y en observancia a la normativa vigente de la SBS. Aquellos hechos que califiquen como sospechosos podrán ser informados como tales de acuerdo con la normativa de prevención de LAFT.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>DE LA INFORMACIÓN REGISTRADA POR EL USUARIO</h3>
                <p>EL USUARIO, al registrarse y utilizar la plataforma de <b>Cambios JP</b>, declara que toda la información proporcionada es verdadera, completa y exacta. EL USUARIO es responsable por la veracidad, exactitud, vigencia y autenticidad de la información suministrada, y se compromete a mantenerla debidamente actualizada.</p>

                <p>Sin perjuicio de lo anterior, EL USUARIO autoriza a <b>Cambios JP</b> a verificar la veracidad de los datos personales facilitados por el Usuario a través de información obtenida de fuentes de acceso público o entidades especializadas en la provisión de dicha información.</p>
            
                <p><b>Cambios JP</b> no se hace responsable de la veracidad de la información que no sea de elaboración propia, por lo que tampoco asume responsabilidad alguna por posibles daños o perjuicios que pudieran originarse por el uso de dicha información.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>DE LAS MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES</h3>
                <p><b>Cambios JP</b> se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento los presentes términos y condiciones.</p>
            
                <p>Cualquier modificación, actualización o aplicación de los presentes términos y condiciones, será inmediatamente publicada en nuestra plataforma, siendo responsabilidad de EL USUARIO revisar los términos y condiciones vigentes de forma periódica, especialmente antes de realizar la operación.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>TRATAMIENTO DE DATOS Y POLÍTICAS DE PRIVACIDAD</h3>
                <p>1. El USUARIO declara y acepta que todos los datos registrados y/o enviados en la web y aplicativos de <b>Cambios JP</b> tienen el carácter de <b>Declaración Jurada</b> por lo que su veracidad es de total y entera responsabilidad de EL USUARIO ante las leyes peruanas.</p>
    
                <p>2. Los datos registrados por EL USUARIO son de tratamiento exclusivo de <b>Cambios JP</b> por lo que no podrá ceder, compartir y/o comercializar dicha información con nadie salvo que exista un mandato emitido por autoridad competente o que lo solicite la Unidad de Inteligencia Financiera de la SBS conforme a lo establecido por la normativa reguladora de dicha entidad reguladora del estado Peruano.</p>
            
                <p>3. <b>Cambios JP</b>, bajo ningún motivo o medio, solicita información sobre claves de tarjetas o códigos de seguridad de sus cuentas bancarias o tarjetas. Igualmente, <b>Cambios JP</b> no solicita por ningún medio o motivo las contraseñas de acceso a sus cuentas de <a href="www.cambiosjp.com"> www.cambiosjp.com</a>.</p>
            
                <p>4. Es deber y obligación de El USUARIO el proteger sus contraseñas de acceso a su cuenta de <a href="www.cambiosjp.com"> www.cambiosjp.com </a> El USUARIO no debe prestar o compartir la contraseña de sus cuentas de acceso por lo que el uso de la cuenta y protección de su contraseña es de su entera responsabilidad.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>ATENCIÓN DE RECLAMOS Y SOLICITUDES</h3>
                <p>Nuestro libro virtual de reclamaciones y quejas se encuentra disponible en la plataforma. Asimismo, para cualquier comunicación puedes enviarnos un correo a <b>contacto@cambiosjp.com</b>.</p>
            
            </div>

            <div class="privacy-policy-content">
                <h3>HORARIOS DE ATENCIÓN</h3>
                <p>El horario de atención de la plataforma de <b>Cambios JP</b> es de lunes a viernes de 9:00 am hasta las 7:00 pm, los sábados el horario será de 09:00 am hasta las 2:00 pm, siempre y cuando sean días hábiles, es decir, se excluyen los feriados locales. Por lo tanto, las operaciones y transferencias a ser solicitadas por parte de EL USUARIO deberán realizarse de acuerdo a lo antes señalado.</p>
            
                <p>Si la transferencia es realizada o llega a las cuentas de <b>Cambios JP</b> fuera del horario de atención, la operación podrá ser anulada o aceptada bajo las condiciones que <b>Cambios JP</b> considere pertinentes.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>LEY, JURISDICCIÓN y COMPETENCIA</h3>
                <p>EL USUARIO renuncia expresamente a cualquier otra jurisdicción que pudiera corresponderle en razón de su domicilio presente o futuro, por lo que se somete a la competencia y jurisdicción de los jueces y tribunales de Lima. Todas las notificaciones extrajudiciales o judiciales a que hubiera lugar se realizarán en el domicilio consignado en el registro de EL USUARIO. Cualquier cambio del domicilio deberá ser actualizado por EL USUARIO, a través de nuestra plataforma.</p>  
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'TermsConditionArea'
    }
</script>