<template>
    <div class="ctp-team-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Executive Team</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="../../assets/images/currency-transfer-provider/team/team1.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>Michele Allen</h3>
                            <span>Chairman</span>

                            <div class="icon">
                                <a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="../../assets/images/currency-transfer-provider/team/team2.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>James Tucker</h3>
                            <span>Chief Executive Officer</span>

                            <div class="icon">
                                <a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="../../assets/images/currency-transfer-provider/team/team3.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>Doris Dickinson</h3>
                            <span>Director</span>

                            <div class="icon">
                                <a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="../../assets/images/currency-transfer-provider/team/team4.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>William Pipes</h3>
                            <span>Head of Compliance</span>

                            <div class="icon">
                                <a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>