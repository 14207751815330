<template>
    <div class="featured-boxes-area">
        <div class="container">
            <div class="featured-boxes-inner">
                <div class="row m-0">
                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-fb7756">
                                <i class="flaticon-piggy-bank"></i>
                            </div>

                            <h3>Precios transparentes</h3>
                            <p>Te ofrecemos precios claros y sin sorpresas, para que siempre sepas cuánto obtendrás por tu dinero.</p>

                     <!--   <router-link to="/features-1" class="read-more-btn">Read More</router-link>-->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-facd60">
                                <i class="flaticon-data-encryption"></i>
                            </div>

                            <h3>Comodidad</h3>
                            <p>Cambia dólares en línea desde cualquier lugar con conexión a internet.</p>

                      <!--  <router-link to="/features-1" class="read-more-btn">Read More</router-link>-->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-1ac0c6">
                                <i class="flaticon-wallet"></i>
                            </div>

                            <h3>Rapidez</h3>
                            <p>Agilidad en línea para cambiar tus dólares. Transacciones rápidas desde cualquier ubicación, sin largas esperas.</p>

                    <!--    <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon">
                                <i class="flaticon-shield"></i>
                            </div>

                            <h3>Seguridad</h3>
                            <p>Cambiamos tus dólares con seguridad y confianza. Transacciones protegidas para tu tranquilidad.</p>

                    <!--    <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Featured'
    }
</script>