<template>
    <div class="ctp-investors-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Investors</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors1.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors2.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors3.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors4.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors5.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <img src="../../assets/images/currency-transfer-provider/investors/investors6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Investors'
}
</script>