<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Freelancers, entrepreneurs, and sole traders</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                            <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                        </ul>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-1.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/images/circle.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Services'
    }
</script>