<template>
    <div class="blog-area ptb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 15, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    The security risks of changing package owners
                                </router-link>
                            </h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 17, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/blog-details">Tips to Protecting Business and Family</router-link></h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 19, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/blog-details">Protect Your Workplace from Cyber Attacks</router-link></h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-4.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 15, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/blog-details">Business Debit Fees to Increase in 2019</router-link></h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-5.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 17, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/blog-details">10 Tips To Reduce Your Card Processing Costs</router-link></h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-6.jpg" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> September 19, 2019
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/blog-details">PayPal Here Card Reader Review 2019</router-link></h3>

                            <span>By <a href="#">admin</a></span>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                            <router-link to="/blog-details" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="/blog-1" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                        <a href="/blog-1" class="page-numbers">1</a>
                        <span class="page-numbers current" aria-current="page">2</span>
                        <a href="/blog-1" class="page-numbers">3</a>
                        <a href="/blog-1" class="page-numbers">4</a>
                        <a href="/blog-1" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Blog'
    }
</script>