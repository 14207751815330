<template>
    <div class="blog-area ptb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 15, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3>
                                        <router-link to="/blog-details">
                                            The security risks of changing package owners
                                        </router-link>
                                    </h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 17, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3><router-link to="/blog-details">Tips to Protecting Business and Family</router-link></h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 19, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3><router-link to="/blog-details">Protect Your Workplace from Cyber Attacks</router-link></h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-4.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 15, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3><router-link to="/blog-details">Business Debit Fees to Increase in 2022</router-link></h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-5.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 17, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3><router-link to="/blog-details">10 Tips To Reduce Your Card Processing Costs</router-link></h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/blog-6.jpg" alt="image">
                                    </router-link>

                                    <div class="date">
                                        <i class="far fa-calendar-alt"></i> September 19, 2019
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <h3><router-link to="/blog-details">PayPal Here Card Reader Review 2022</router-link></h3>

                                    <span>By <a href="#">admin</a></span>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis ipsum suspendisse ultrices gravida.</p>

                                    <router-link to="/blog-details" class="read-more-btn">
                                        Read More <i class="fas fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <a href="/blog-2" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                                <a href="/blog-2" class="page-numbers">1</a>
                                <span class="page-numbers current" aria-current="page">2</span>
                                <a href="/blog-2" class="page-numbers">3</a>
                                <a href="/blog-2" class="page-numbers">4</a>
                                <a href="/blog-2" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area" id="secondary">
                        <section class="widget widget_search">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>
                                <button type="submit"><i class="fas fa-search"></i></button>
                            </form>
                        </section>

                        <section class="widget widget_vibion_posts_thumb">
                            <h3 class="widget-title">Popular Posts</h3>

                            <article class="item">
                                <a href="#" class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2019-06-30">June 10, 2019</time>
                                    <h4 class="title usmall"><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a href="#" class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2019-06-30">June 21, 2019</time>
                                    <h4 class="title usmall"><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a href="#" class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2019-06-30">June 30, 2019</time>
                                    <h4 class="title usmall"><a href="#">How To Create A Responsive Popup Gallery?</a></h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </section>

                        <section class="widget widget_recent_entries">
                            <h3 class="widget-title">Recent Posts</h3>

                            <ul>
                                <li><a href="#">How to Become a Successful Entry Level UX Designer</a></li>
                                <li><a href="#">How to start your business as an entrepreneur</a></li>
                                <li><a href="#">How to be a successful entrepreneur</a></li>
                                <li><a href="#">10 Building Mobile Apps With Ionic And React</a></li>
                                <li><a href="#">Protect your workplace from cyber attacks</a></li>
                            </ul>
                        </section>

                        <section class="widget widget_archive">
                            <h3 class="widget-title">Archives</h3>

                            <ul>
                                <li><a href="#">May 2019</a></li>
                                <li><a href="#">April 2019</a></li>
                                <li><a href="#">June 2019</a></li>
                            </ul>
                        </section>

                        <section class="widget widget_categories">
                            <h3 class="widget-title">Categories</h3>

                            <ul>
                                <li><a href="#">Business</a></li>
                                <li><a href="#">Privacy</a></li>
                                <li><a href="#">Technology</a></li>
                                <li><a href="#">Tips</a></li>
                                <li><a href="#">Uncategorized</a></li>
                            </ul>
                        </section>

                        <section class="widget widget_meta">
                            <h3 class="widget-title">Meta</h3>

                            <ul>
                                <li><a href="#">Log in</a></li>
                                <li><a href="#">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a href="#">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a href="#">WordPress.org</a></li>
                            </ul>
                        </section>

                        <section class="widget widget_tag_cloud">
                            <h3 class="widget-title">Tags</h3>

                            <div class="tagcloud">
                                <a href="#">IT <span class="tag-link-count"> (3)</span></a>
                                <a href="#">Vibion <span class="tag-link-count"> (3)</span></a>
                                <a href="#">Games <span class="tag-link-count"> (2)</span></a>
                                <a href="#">Fashion <span class="tag-link-count"> (2)</span></a>
                                <a href="#">Travel <span class="tag-link-count"> (1)</span></a>
                                <a href="#">Smart <span class="tag-link-count"> (1)</span></a>
                                <a href="#">Marketing <span class="tag-link-count"> (1)</span></a>
                                <a href="#">Tips <span class="tag-link-count"> (2)</span></a>
                            </div>
                        </section>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BlogRightSidebar'
    }
</script>