<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-2.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/images/circle.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <h2>Small- to medium-sized businesses</h2>
                        <div class="bar"></div>
                        <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                            <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesTwo'
    }
</script>