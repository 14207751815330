<template>
    <div class="ctp-working-people-area ptb-100">
        <div class="container-fluid">
            <div class="row justify-content-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="ctp-working-people-content">
                        <h3>Being A Money Transfer Service For Hard Working People</h3>

                        <div class="working-people-inner-card">
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/working-people/save-time.svg" alt="image">
                            </div>
                            <h4>Save time</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. donec rutrum congue leo eget malesuada.</p>
                        </div>

                        <div class="working-people-inner-card">
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/working-people/money-saving.svg" alt="image">
                            </div>
                            <h4>Save money</h4>
                            <p>Nulla porttitor accumsan tincidunt. quisque velit nisi, pretium ut lacinia in, elementum id enim. praesent sapien massa, convallis a pellentesque nec, egestas non nisi. sed porttitor lectus nibh.</p>
                        </div>

                        <div class="working-people-inner-card">
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/working-people/secure.svg" alt="image">
                            </div>
                            <h4>Send securely</h4>
                            <p>Cras ultricies ligula sed magna dictum porta. donec rutrum congue leo eget malesuada. curabitur aliquet quam id dui posuere blandit. vivamus suscipit tortor eget felis porttitor volutpat.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="ctp-working-people-image jarallax" data-jarallax='{"speed": 0.3}'></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkingPeople'
}
</script>