<template>
    <div class="ctp-countries-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Popular Countries Our Customers Send Money</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img1.png" alt="image">
                        <span>USA</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img2.png" alt="image">
                        <span>UK</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img3.png" alt="image">
                        <span>New Zealand</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img4.png" alt="image">
                        <span>France</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img5.png" alt="image">
                        <span>Germany</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img6.png" alt="image">
                        <span>Italy</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img7.png" alt="image">
                        <span>Greece</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img8.png" alt="image">
                        <span>China</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img9.png" alt="image">
                        <span>Kuwait</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img10.png" alt="image">
                        <span>Argentina</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img11.png" alt="image">
                        <span>Sweden</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="../../assets/images/currency-transfer-provider/countries/img12.png" alt="image">
                        <span>Thailand</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Countries'
}
</script>