<template>
    <div class="ctp-funfacts-area">
        <div class="container">
            <div class="ctp-funfacts-inner-box pt-100 pb-75">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3>180K+</h3>
                            <p>Happy Customers</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3>50+</h3>
                            <p>Currencies Offered</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3>1M+</h3>
                            <p>Customers Globally</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3>30K+</h3>
                            <p>5-Star Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>