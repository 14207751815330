<template>
    <div class="features-section ptb-70 bg-f7fafd">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="features-box-list">
                        <div class="row">
                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon">
                                        <i class="flaticon-settings"></i>
                                    </div>

                                    <h3>Incredible infrastructure</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-f78acb">
                                        <i class="flaticon-envelope-of-white-paper"></i>
                                    </div>

                                    <h3>Email notifications</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-cdf1d8">
                                        <i class="flaticon-menu"></i>
                                    </div>

                                    <h3>Simple dashboard</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-c679e3">
                                        <i class="flaticon-info"></i>
                                    </div>

                                    <h3>Information retrieval</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="features-image">
                        <img src="../../assets/images/features-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeatureTwo'
    }
</script>