<template>
    <div class="account-create-area">
        <div class="container">
            <div class="account-create-content">
                <h2>Solicita tu cambio en minutos</h2>
                <p>¡Obtenga su cuenta de Cambios JP hoy!</p>
                <a href="https://forms.gle/no9u9SFdKFLmoHJo7" class="btn btn-primary">
                    REGÍSTRATE
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountCreate'
    }
</script>