<template>
    <div class="how-it-works-area ptb-70">
        <div class="container">
            <div class="section-title">
                <h2>¿Cómo Funciona?</h2>
                <div class="bar"></div>
                <p>Compra o vende tus dólares en 4 simples pasos, en cuestión de minutos con el mejor tipo de cambio.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/how-it-works/how-it-works-1.png" alt="image">

                        <h3>1. Cotiza tu operación</h3>
                        <p>Verifica el tipo de cambio en tiempo real desde la calculadora de nuestra web.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/how-it-works/how-it-works-2.png" alt="image">

                        <h3>2. Solicita el cambio</h3>
                        <p>Escribe a nuestro whatsapp y solicita el cambio. Te atenderemos en pocos minutos.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/how-it-works/how-it-works-3.png" alt="image">

                        <h3>3. Transfiere a Cambios JP</h3>
                        <p>Transfiere el monto acordado a nuestra cuenta.</p>
                        <br>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/how-it-works/how-it-works-4.png" alt="image">

                        <h3>4. Recibe tu dinero</h3>
                        <p>Tu dinero será transferido a tu cuenta destino.</p>
                        <br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HowItWorks'
    }
</script>