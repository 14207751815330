<template>
    <div class="page-title-section">
        <div class="container">
            <div class="page-title-text">
                <h2>All the tools you need</h2>
                <p>Our Features</p>

                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li>Contact</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>