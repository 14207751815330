<template>
    <div class="ctp-app-area">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-content">
                        <span class="sub">Download App</span>
                        <h3>Let’s get your free copy from apple and play store</h3>
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        <div class="btn-box">
                            <a href="#" class="app-store-btn">
                                <i class="flaticon-apple"></i>
                                Download on
                                <span>App Store</span>
                            </a>

                            <a href="#" class="play-store-btn">
                                <i class="flaticon-play-store"></i>
                                Download on
                                <span>Google play</span>
                            </a>
                        </div>
                        <div class="info">
                            <span>Over 10 million downloads worldwide</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-image">
                        <img src="../../assets/images/currency-transfer-provider/app/app.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DownloadApp'
}
</script>