<template>
    <div class="ctp-about-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-image"></div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-content">
                        <span>About us</span>
                        <h3>We help transfer money for a better world</h3>
                        <p>Donec sollicitudin molestie malesuada. proin eget tortor risus. nulla quis lorem ut libero malesuada feugiat. donec rutrum congue leo eget malesuada.</p>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, ultrices posuere cubilia curae donec auctor sit amet aliquam vel, ullamcorper sit amet ligula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                        <h4>Our mission</h4>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. lorem ipsum dolor sit amet, ultrices posuere cubilia curae donec consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>