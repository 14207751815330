<template>
    <div class="ctp-page-banner-area">
        <div class="container">
            <div class="ctp-page-banner-content">
                <h3>Business</h3>
                <ul class="list">
                    <li>
                        <router-link to="/currency-transfer">Home</router-link>
                    </li>
                    <li>Business</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>